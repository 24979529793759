import { default as _blank7OQDwyOGR5Meta } from "/vercel/path0/pages/_blank.vue?macro=true";
import { default as _91redirect_93np9nop8LuKMeta } from "/vercel/path0/pages/[redirect].vue?macro=true";
import { default as future_45messagesAJO8uq3zw4Meta } from "/vercel/path0/pages/activities/future-messages.vue?macro=true";
import { default as gallery2KouDQGIzLMeta } from "/vercel/path0/pages/activities/gallery.vue?macro=true";
import { default as guestbookbPaRd1pNEgMeta } from "/vercel/path0/pages/activities/guestbook.vue?macro=true";
import { default as paintzGKa4W1JqMMeta } from "/vercel/path0/pages/activities/paint.vue?macro=true";
import { default as adventuresGydh9CJNBQMeta } from "/vercel/path0/pages/admin/adventures.vue?macro=true";
import { default as future_45messages3nTil65cCvMeta } from "/vercel/path0/pages/admin/future-messages.vue?macro=true";
import { default as guest_45listswjAsMuVoEMeta } from "/vercel/path0/pages/admin/guest-list.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as save_45the_45datesKvdFg3O2XFMeta } from "/vercel/path0/pages/admin/save-the-dates.vue?macro=true";
import { default as scheduleCVR8osT2sqMeta } from "/vercel/path0/pages/admin/schedule.vue?macro=true";
import { default as rsvpxquoiaFnPNMeta } from "/vercel/path0/pages/communications/rsvp.vue?macro=true";
import { default as _91id_93VDpON3aWVzMeta } from "/vercel/path0/pages/communications/save-the-date/[id].vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexGnHiNaJdjPMeta } from "/vercel/path0/pages/legal/index.vue?macro=true";
import { default as privacy_45policy2kyOpSDkIXMeta } from "/vercel/path0/pages/legal/privacy-policy.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as indexGOeHuBcGuCMeta } from "/vercel/path0/pages/our-story/index.vue?macro=true";
import { default as our_45adventuresDYdIwsYOUUMeta } from "/vercel/path0/pages/our-story/our-adventures.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as recapUL5hO1XB4YMeta } from "/vercel/path0/pages/recap.vue?macro=true";
import { default as registrykV3TsfTeFRMeta } from "/vercel/path0/pages/registry.vue?macro=true";
import { default as screennMMFFSuTWxMeta } from "/vercel/path0/pages/screen.vue?macro=true";
import { default as the_45big_45day0gq4WXeFK5Meta } from "/vercel/path0/pages/the-big-day.vue?macro=true";
import { default as umbracofdr0aQ6IYyMeta } from "/vercel/path0/pages/umbraco.vue?macro=true";
import { default as wedding_45partyMje69uaSLtMeta } from "/vercel/path0/pages/wedding-party.vue?macro=true";
export default [
  {
    name: "_blank",
    path: "/_blank",
    meta: _blank7OQDwyOGR5Meta || {},
    component: () => import("/vercel/path0/pages/_blank.vue")
  },
  {
    name: "redirect",
    path: "/:redirect()",
    component: () => import("/vercel/path0/pages/[redirect].vue")
  },
  {
    name: "activities-future-messages",
    path: "/activities/future-messages",
    meta: future_45messagesAJO8uq3zw4Meta || {},
    component: () => import("/vercel/path0/pages/activities/future-messages.vue")
  },
  {
    name: "activities-gallery",
    path: "/activities/gallery",
    meta: gallery2KouDQGIzLMeta || {},
    component: () => import("/vercel/path0/pages/activities/gallery.vue")
  },
  {
    name: "activities-guestbook",
    path: "/activities/guestbook",
    meta: guestbookbPaRd1pNEgMeta || {},
    component: () => import("/vercel/path0/pages/activities/guestbook.vue")
  },
  {
    name: "activities-paint",
    path: "/activities/paint",
    component: () => import("/vercel/path0/pages/activities/paint.vue")
  },
  {
    name: "admin-adventures",
    path: "/admin/adventures",
    meta: adventuresGydh9CJNBQMeta || {},
    component: () => import("/vercel/path0/pages/admin/adventures.vue")
  },
  {
    name: "admin-future-messages",
    path: "/admin/future-messages",
    meta: future_45messages3nTil65cCvMeta || {},
    component: () => import("/vercel/path0/pages/admin/future-messages.vue")
  },
  {
    name: "admin-guest-list",
    path: "/admin/guest-list",
    meta: guest_45listswjAsMuVoEMeta || {},
    component: () => import("/vercel/path0/pages/admin/guest-list.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    component: () => import("/vercel/path0/pages/admin/index.vue")
  },
  {
    name: "admin-save-the-dates",
    path: "/admin/save-the-dates",
    meta: save_45the_45datesKvdFg3O2XFMeta || {},
    component: () => import("/vercel/path0/pages/admin/save-the-dates.vue")
  },
  {
    name: "admin-schedule",
    path: "/admin/schedule",
    meta: scheduleCVR8osT2sqMeta || {},
    component: () => import("/vercel/path0/pages/admin/schedule.vue")
  },
  {
    name: "communications-rsvp",
    path: "/communications/rsvp",
    meta: rsvpxquoiaFnPNMeta || {},
    component: () => import("/vercel/path0/pages/communications/rsvp.vue")
  },
  {
    name: "communications-save-the-date-id",
    path: "/communications/save-the-date/:id()",
    meta: _91id_93VDpON3aWVzMeta || {},
    component: () => import("/vercel/path0/pages/communications/save-the-date/[id].vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactJnsDpYVejzMeta || {},
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/vercel/path0/pages/legal/index.vue")
  },
  {
    name: "legal-privacy-policy",
    path: "/legal/privacy-policy",
    component: () => import("/vercel/path0/pages/legal/privacy-policy.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/vercel/path0/pages/logout.vue")
  },
  {
    name: "our-story",
    path: "/our-story",
    meta: indexGOeHuBcGuCMeta || {},
    component: () => import("/vercel/path0/pages/our-story/index.vue")
  },
  {
    name: "our-story-our-adventures",
    path: "/our-story/our-adventures",
    meta: our_45adventuresDYdIwsYOUUMeta || {},
    component: () => import("/vercel/path0/pages/our-story/our-adventures.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilekxSCn5bMpQMeta || {},
    component: () => import("/vercel/path0/pages/profile.vue")
  },
  {
    name: "recap",
    path: "/recap",
    meta: recapUL5hO1XB4YMeta || {},
    component: () => import("/vercel/path0/pages/recap.vue")
  },
  {
    name: "registry",
    path: "/registry",
    meta: registrykV3TsfTeFRMeta || {},
    component: () => import("/vercel/path0/pages/registry.vue")
  },
  {
    name: "screen",
    path: "/screen",
    meta: screennMMFFSuTWxMeta || {},
    component: () => import("/vercel/path0/pages/screen.vue")
  },
  {
    name: "the-big-day",
    path: "/the-big-day",
    meta: the_45big_45day0gq4WXeFK5Meta || {},
    component: () => import("/vercel/path0/pages/the-big-day.vue")
  },
  {
    name: "umbraco",
    path: "/umbraco",
    component: () => import("/vercel/path0/pages/umbraco.vue")
  },
  {
    name: "wedding-party",
    path: "/wedding-party",
    meta: wedding_45partyMje69uaSLtMeta || {},
    component: () => import("/vercel/path0/pages/wedding-party.vue")
  }
]