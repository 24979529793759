import event from "~/event.json";
import type { ScheduleItem } from "~/resources/useAdminScheduleResource";

export type ScheduleItemStatus = "current" | "complete" | null;

export type ScheduleItemDefinition = {
  id: string;
  time: string;
  title: string;
};

export type ScheduleItemInstance = ScheduleItemDefinition & {
  status: ScheduleItemStatus;
};

export const useEventStore = defineStore( "phase", () => {
  const phases = ["preEvent", "dayOfEvent", "ceremony", "event", "postEvent"] as const;

  type Phase = typeof phases[number];

  const dateTime = ref( new Date( event.date ) );
  const location = ref( "Burlington, KY" );
  const realPhase = ref<Phase>( "preEvent" );
  const overridePhase = ref<Phase>();
  const phase = computed( () => {
    return overridePhase.value ? overridePhase.value : realPhase.value;
  } );

  function togglePhase () {
    let newPhase: Phase | undefined;

    const realPhaseIndex = phases.indexOf( realPhase.value );
    const overridePhaseIndex = overridePhase.value
      ? phases.indexOf( overridePhase.value )
      : -1;

    if ( overridePhaseIndex === -1 ) {
      // No override phase set, move to the next phase after the real phase
      if ( realPhaseIndex === phases.length - 1 ) {
        newPhase = phases[0];
      }
      else {
        newPhase = phases[realPhaseIndex + 1];
      }
    }
    else {
      // Override phase is set, cycle through override phases
      if ( overridePhaseIndex === phases.length - 1 ) {
        newPhase = phases[0];
      }
      else {
        newPhase = phases[overridePhaseIndex + 1];
      }

      // If we circle back to the real phase, null out the override phase
      if ( newPhase === realPhase.value ) {
        newPhase = undefined;
      }
    }

    overridePhase.value = newPhase;
  }

  const items = ref<ScheduleItemDefinition[]>( [] );

  function setItems ( value: ScheduleItem[] ) {
    items.value = value.map( x => ( {
      id: x.id,
      time: x.startTime,
      title: x.title,
    } ) );
  }

  const schedule = computed<ScheduleItemInstance[]>( () => items.value.map( ( x ) => {
    let status: ScheduleItemStatus = null;

    if ( isCurrent( x ) ) {
      status = "current";
    }
    else if ( isComplete( x ) ) {
      status = "complete";
    }

    const result: ScheduleItemInstance = {
      ...x,
      status,
    };

    return result;
  } ) );

  function isComplete ( item: ScheduleItemDefinition ) {
    if ( phase.value === "postEvent" ) {
      return true;
    }

    if ( phase.value === "event" && item.title === "Ceremony" ) {
      return true;
    }
  }

  function isCurrent ( item: ScheduleItemDefinition ) {
    if ( phase.value === "ceremony" && item.title === "Ceremony" ) {
      return true;
    }

    if ( phase.value === "event" && item.title === "Cocktail Hour" ) {
      return true;
    }
  }

  return {
    togglePhase,
    phase,
    realPhase,
    overridePhase,
    dateTime,
    location,
    schedule,
    setItems,
  };
} );
